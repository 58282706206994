.AboutMe {
  margin-bottom: 2rem;
}

.AboutMe-WebPhoto {
  height: calc(100vh - 9rem - 68px);
  filter: opacity(80%);
}

.AboutMe-Office {
  filter: opacity(80%);
  margin: 0.5rem;
  max-width: 600px;
  width: 100%;
}

.AboutMe-MobilePhotoContainer {
  display: none;
}

.AboutMe-Content {
  flex: 1;
  padding: 0 4rem;
}

.AboutMe-QuoteContainer {
  .AboutMe-Quote {
    font-weight: bold;
    margin-bottom: 0.2rem;
  }

  .AboutMe-Author {
    font-weight: bold;
    margin-left: 0.5rem;
    margin-top: 0;
  }
}

@media only screen and (max-width: 1100px) {
  .AboutMe-WebPhoto {
    display: none;
  }

  .AboutMe-MobilePhotoContainer {
    border-radius: 50%;
    display: inline-block;
    height: 300px;
    margin-bottom: 1rem;
    overflow: hidden;
    max-width: 300px;
  }

  .AboutMe-MobilePhoto {
    filter: opacity(80%);
    width: 100%;
  }

  .AboutMe-Content {
    padding: 0;
    text-align: center;
  }
}
