@import '~styles/theme';

.Navigation {
  padding: 0.75rem 0;
  background-color: $light;
}

.Navigation-Container {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.Navigation-PageContainer {
  display: flex;
  align-items: center;
}

.Navigation-LogoContainer {
  display: flex;
  flex: 1;
  text-decoration: none;
}

.Navigation-Photo {
  height: 44px;
}

.Navigation-Logo,
.Navigation-Link {
  text-decoration: none;
  padding: 0.75rem;
  white-space: nowrap;
  color: $dark;
}

.Navigation-Logo {
  font-weight: bold;
  text-transform: uppercase;
}

.Navigation-Link {
  font-weight: 300;
  margin-right: 0.5rem;
  border-bottom: 1px solid $light;
  transition: all 0.3s;
}

.Navigation-Icon {
  margin-right: 0.5rem;
}

.Navigation-IconLink {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Navigation-Link.Navigation-Link--mobile,
.Navigation-Link.Navigation-Link--mobile:hover {
  padding: 0.75rem 1.75rem;
  border-bottom: 1px solid $light;
  margin: 0;
}

.Navigation-Link.Navigation-Link--mobile.Navigation-Link--selected,
.Navigation-Link.Navigation-Link--mobile.Navigation-Link--selected:hover {
  background-color: $light;
  border-bottom: 1px solid $light;
}

.Navigation-Link:last-of-type {
  margin: 0;
}

.Navigation-Link.Navigation-Link--selected,
.Navigation-Link.Navigation-Link--selected:hover {
  border-bottom: 1px solid $dark;
  cursor: default;
}

.Navigation-Link:hover {
  border-bottom: 1px solid $light-medium;
}

.Navigation-Ham.hamburger {
  outline: none;
  padding: 5px 15px 5px 5px;
  position: relative;
}

.Navigation-Ham.hamburger:hover {
  opacity: 1;
}

.Navigation-Ham .hamburger-box {
  width: 30px;
  height: 20px;
}

.Navigation-Ham .hamburger-inner,
.Navigation-Ham .hamburger-inner:before,
.Navigation-Ham .hamburger-inner:after {
  width: 30px;
  height: 3px;
}

.Navigation-Content {
  display: none;
  z-index: 1;
}

.is-active .Navigation-Content {
  display: flex;
  position: absolute;
  flex-direction: column;
  right: 0;
  background-color: white;
  top: 110%;
}
