@import '~styles/theme';

.PageContainer {
  display: flex;
  flex: 1;
  background-color: $light-medium;
  margin-bottom: 3rem;
  padding: 3rem;
  position: relative;
}

.PageContainer h1 {
  color: $medium-dark;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 2rem;
  margin-top: 0;
  letter-spacing: 6px;
}

.PageContainer h2 {
  color: $medium-dark;
  padding-bottom: 0.3rem;
  font-weight: 300;
  font-size: 1.2rem;
  margin: 0 0 3rem;
}

.PageContainer h3 {
  color: $medium;
  border-bottom: 2px solid $light;
  padding-bottom: 0.3rem;
  font-weight: 500;
  font-size: 1.1rem;
  margin-top: 0;
}

.PageContainer p {
  color: $medium;
  font-weight: 300;
  line-height: 1.4;
  margin-bottom: 1.5rem;
}

@media only screen and (max-width: 900px) {
  .PageContainer {
    margin: 0;
  }
}
