@import '~styles/theme';

.FeesAndInsurance {
  flex-direction: column;
  align-items: center;
}

.FeesAndInsurance-Container {
  max-width: 800px;
}

.FeesAndInsurance-Fees {
  color: $medium;
  font-weight: 300;
  line-height: 1.4;
  margin-bottom: 1.5rem;

  li {
    margin-bottom: 0.2rem;
  }
}
