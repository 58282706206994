@import '~styles/theme';

.Button {
  background-color: $medium;
  color: $light;
  border-radius: 4px;
  padding: 0.8rem 2rem;
  font-weight: 300;
  text-decoration: none;
  display: inline-block;
  border: none;
}
