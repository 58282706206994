@import '~styles/theme';

body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  letter-spacing: 2px;
  background-color: $light;
  color: $dark;
}

html,
body,
#root {
  min-height: 100vh;
}

@media only screen and (max-width: 900px) {
  body {
    background-color: #e0e0e0;
  }
}
