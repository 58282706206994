@import '~styles/theme';

.Services {
  flex-direction: column;
  align-items: center;
}

.Services-Container {
  max-width: 800px;
}

.Services-List {
  color: $medium;
  flex: 1;
  font-weight: 300;
  margin-top: 0;
  padding-bottom: 1.5rem;

  li {
    margin-bottom: 0.2rem;
  }
}
