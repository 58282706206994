.Contact {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.Contact-Container {
  display: flex;
  flex: 1;
}

.Contact-Address {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 1.5rem;
}

.Contact-AddressContainer {
  display: flex;
}

.Contact-AddressContainer p {
  margin-top: 0;
}

p.Contact-ETOO {
  white-space: nowrap;
  font-weight: 500;
  font-size: 0.9rem;
  // margin-left: 1rem;
}

.Contact-Map {
  width: 100%;
  flex: 1;
  display: block;
  pointer-events: none;
}

.Contact-FormContainer {
  display: flex;
  flex: 1;
  margin-left: 1.5rem;
  flex-direction: column;
}

.Contact-Form {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.Contact-Textarea {
  flex: 1;
  min-height: 70px;
}

@media only screen and (max-width: 600px) {
  .Contact-AddressContainer {
    flex-direction: column;
  }

  p.Contact-ETOO {
    margin-left: 0;
  }
}

@media only screen and (max-width: 900px) {
  .Contact-Container {
    flex-direction: column;
  }

  .Contact-Map {
    height: 300px;
  }

  .Contact-Address {
    margin-bottom: 3rem;
  }

  .Contact-Address,
  .Contact-FormContainer {
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (max-width: 900px) {
  .Contact-Address,
  .Contact-FormContainer,
  .Contact-Form {
    display: block;
  }

  .Contact-FormContainer {
    padding-bottom: 3rem;
  }
}
