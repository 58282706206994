@import '~styles/theme';

.Input[type='text'],
.Input[type='email'],
.Input[type='tel'],
textarea.Input {
  padding: 0.75rem;
  display: block;
  width: calc(100% - 1.5rem);
  border: 1px solid $light-medium;
  border-radius: 5px;
  background-color: $light;
  margin-bottom: 1.5rem;
  resize: none;
}
