@import '~styles/theme';
@import '~styles/utilities';

.Home,
.Home-Filter,
.Home-ContentContainer {
  display: flex;
  flex: 1;
}

.Home-FlexContainer {
  display: flex;
}

.Home {
  margin-bottom: 3rem;
  background-image: url('/images/background.jpg');
  background-size: cover;
  background-position-x: 75%;
  background-position-y: 70%;
}

.Home-Filter {
  background-color: rgba(255, 255, 255, 0.5);
}

.Home-ContentContainer {
  padding-left: 42%;
  padding-top: 10%;
  flex-direction: column;

  h1 {
    margin: 0;
    color: $medium-dark;
    font-size: variableSize(36, 48);
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 0.4rem;
    white-space: normal;
  }

  h2 {
    margin: 0;
    color: $medium;
    font-weight: 500;
    font-size: 1.3rem;
    margin-top: 2rem;
  }
}

.Home-SubHeader {
  max-width: 32rem;
}

.Home-QuoteAuthor {
  font-size: 1.1rem;
  font-weight: 500;
  margin: 1rem 0 3rem 0.5rem;

  a {
    color: $medium-dark;
  }
}

@media only screen and (max-width: 900px) {
  .Home {
    margin: 0;
  }
}

@media only screen and (max-width: 700px) {
  .Home-ContentContainer {
    padding-left: 0;
    margin: 0 2rem;
    text-align: center;
  }

  .Home-FlexContainer {
    justify-content: center;
  }

  .Home-SubHeader {
    max-width: 100%;
  }
}
