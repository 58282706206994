.AppContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 3rem;
}

@media only screen and (max-width: 900px) {
  .AppContainer {
    margin: 0;
  }
}